import apiProvider from "./provider";
import { getErrorMessage } from "./utils";
import qs from "qs";

export const GET = async (
  url: string,
  params?: any,
): Promise<any> => {
  try {
    const response = await apiProvider.get(url, {
      params,
      paramsSerializer(input: any) {
        return qs.stringify(input, { arrayFormat: 'repeat' });
      },
    });
    return {
      isSuccess: true,
      data: response?.data?.data || response?.data,
      status: response.status,
    };
  } catch (err: any) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
      status: err && err.response && err.response.status,
    };
  }
};

export const POST = async (
  url: string,
  body: any,
): Promise<any> => {
  try {
    const response = await apiProvider.post(url, body);
    const dataToReturn = {
      isSuccess: true,
      data: response?.data?.data || response?.data,
      message: response?.data?.message,
    };
    return dataToReturn;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const PUT = async (url: string, file: any, type: string) => {
  try {
      const response = await apiProvider.put(
          url, file,
          {
              headers: {
                  'Content-Type': type,
              },
          },
      );
      return {
          response,
          isSuccess: true,
      };
  } catch (err) {
      const errorMessage = getErrorMessage(err);
      return {
          errorMessage,
          isSuccess: false,
      };
  }
};

export const DELETE = async (url: string) => {
  try {
      const response = await apiProvider.delete(
          url,
      );
      return {
          response,
          isSuccess: true,
      };
  } catch (err) {
      const errorMessage = getErrorMessage(err);
      return {
          errorMessage,
          isSuccess: false,
      };
  }
};
