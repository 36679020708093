import CreateProfile from 'components/CreateProfile';
import './App.css';
import 'antd/dist/antd.less';
import { bindActionCreators } from 'redux';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setMetaData, setUserAuth, setUserData } from 'actions/masterdata';
import { fetchMasterData, fetchUserData } from 'api/createProfile';
import ErrorPage from 'common/ErrorPage';
import Loader from 'components/Loader';

const App = (props: any) => {
  const { setUserAuth, setUserData, setMetaData } = props;
  const userKey = window?.location?.pathname?.split('selfregister')?.[1];

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setUserAuth(userKey);
    if(userKey){
      getUserData();
    }
  }, []);

  const getUserData = async () => {
    setLoading(true);
    const response = await fetchUserData();
    if(response.isSuccess){
      setUserData({
        email: response.data?.email,
        phone: response.data?.phone,
        seller_id: response.data?.id,
      });
      fetchMetadata();
    } else {
      setErrorMessage(response.status === 404 ? 'The page you are looking for could not be found.' : response.errorMessage);
      setIsError(true);
      setLoading(false);
    }
  }

  const fetchMetadata = async () => {
    const response = await fetchMasterData();
    if(response.isSuccess){
      setMetaData(response.data);
    } else {
      setErrorMessage(response.status === 404 ? 'The page you are looking for could not be found.' : response.errorMessage);
      setIsError(true);
    }
    setLoading(false);
  }

  if(!userKey || userKey === '/'){
    return <ErrorPage message="The page you are looking for could not be found." />
  }
  if(loading){
    return <Loader />
  }
  if(isError){
    return <ErrorPage message={errorMessage} />
  }
  return (
      <CreateProfile />
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setUserAuth,
      setUserData,
      setMetaData,
    },
    dispatch,
  );
};

export default connect(null,mapDispatchToProps)(App)

