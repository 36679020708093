import { SET_CREATE_SUCCESS, SET_META_DATA, SET_USER_AUTH, SET_USER_DATA } from '../actions/constants';

const defaultState = {
  auth: '',
  phone: '',
  email: '',
  seller_id: '',
  metadata: {},
  is_success: false,
  seller_code: '',
};

const masterdataReducer = function (state = defaultState, action: { type: any; data: any; }) {
  switch (action.type) {
    case SET_USER_AUTH: {
      return {
        ...state,
        auth: action.data,
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data,
      }
    }
    case SET_META_DATA: {
      return {
        ...state,
        metadata: {
          ...action.data,
        },
      }
    }
    case SET_CREATE_SUCCESS: {
      return {
        ...state,
        ...action.data,
      }
    }
    default:
      return state;
  }
};

export default masterdataReducer;